import React from "react";

function VelocitatsMitjanes(props) {
  const velocitats_mitjanes = props.velocitats_mitjanes;

  return (
    <div className="quadreLila">
      <h4>Vitesses moyennes</h4>
      <p className="descripcio">
        Référence théorique des vitesses moyennes entre deux gares quand le
        train circule à l'heure. Information en cours de essai.
      </p>
      <div className="llistaVelocitatsMitjanes">
        {velocitats_mitjanes.map((velocitatMitjana, index) => (
          <div className="liniaVelocitatMitjana" key={"velocitatM_" + index}>
            {velocitatMitjana.estacio1} ({velocitatMitjana.poste1}) à{" "}
            {velocitatMitjana.estacio2} ({velocitatMitjana.poste2}):{" "}
            {velocitatMitjana.velocitat !== "VL" && (
              <span className="numeroVelocitatMitjana negreta">
                {velocitatMitjana.velocitat} km/h
              </span>
            )}
            {velocitatMitjana.velocitat === "VL" && (
              <span className="numeroVelocitatMitjana negreta">VL</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default VelocitatsMitjanes;

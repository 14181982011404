import { useState } from "react";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import Api from "../Api";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { HorariImprimir } from "./HorariImprimir";
import { useNavigate } from "react-router-dom";

function FullHorari() {
  const [horari, setHorari] = useState(null);
  const [pantalla, setPantalla] = useState(1);
  const [veurePL, setVeurePL] = useState(false);
  const [veurePK, setVeurePK] = useState(true);
  const [veureNotes, setVeureNotes] = useState(true);

  const navigate = useNavigate();

  // Crear un array amb les variables data i tren
  const params = useParams();
  let data = params.data;
  let numero_tren = params.tren;

  const componentRef = useRef();

  React.useEffect(() => {
    Api.get("/horari/?data=" + data + "&tren=" + numero_tren)
      .then((response) => {
        setHorari(response.data);
        setPantalla(2);
      })
      .catch((error) => {
        navigate("/horari/");
      });

    //Si l'amplada de la pantalla és inferior a 800px, no es mostren els PK
    if (window.innerWidth < 800) {
      setVeurePK(false);
    }
  }, []);

  function canviVeurePL(e) {
    veurePL ? setVeurePL(false) : setVeurePL(true);
  }

  function canviVeurePK(e) {
    veurePK ? setVeurePK(false) : setVeurePK(true);
  }

  function canviVeureNotes(e) {
    veureNotes ? setVeureNotes(false) : setVeureNotes(true);
  }

  return (
    <div>
      {pantalla === 1 && <div>Chargement...</div>}
      {pantalla === 2 && (
        <div>
          <div className="fullHorariBotons">
            <ReactToPrint
              trigger={() => <Button variant="primary">Imprimer</Button>}
              content={() => componentRef.current}
            />
            <span> </span>
            <Button variant="secondary" onClick={canviVeurePL}>
              {!veurePL ? "Voir toutes les gares" : "Voir moins de gares"}
            </Button>
            <span> </span>
            <Button variant="secondary" onClick={canviVeurePK}>
              {!veurePK ? "Voir PK" : "Cacher PK"}
            </Button>
            <span> </span>
            <Button variant="secondary" onClick={canviVeureNotes}>
              {!veureNotes ? "Voir Notes" : "Cacher Notes"}
            </Button>
          </div>
          <HorariImprimir
            ref={componentRef}
            horari={horari}
            veurePL={veurePL}
            veurePK={veurePK}
            veureNotes={veureNotes}
          />
        </div>
      )}
    </div>
  );
}

export default FullHorari;

function Notes(props) {
  const velocitats_mitjanes = props.velocitats_mitjanes;

  return (
    <div className="quadreLila">
      <h4>Mes Notes</h4>
      <p className="descripcio">
        <span className="preInlineBlock">Numéro des contrôleurs: </span>
        <div className="caixaEscriure"></div>{" "}
        <div className="caixaEscriure"></div>
      </p>
      <p className="descripcio margesNotes">
        <div className="liniaNotes"></div>
        <div className="liniaNotes"></div>
        <div className="liniaNotes"></div>
        <div className="liniaNotes"></div>
      </p>
    </div>
  );
}

export default Notes;

import packageInfo from "../../package.json";
import Container from "react-bootstrap/Container";

function Footer() {
  return (
    <Container>
      <div>v.{packageInfo.version}</div>
    </Container>
  );
}

export default Footer;

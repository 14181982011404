import React from "react";
import Horari from "./Horari";

export class HorariImprimir extends React.Component {
  render() {
    return (
      <Horari
        horari={this.props.horari}
        veurePL={this.props.veurePL}
        veurePK={this.props.veurePK}
        veureNotes={this.props.veureNotes}
      />
    );
  }
}

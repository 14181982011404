import React, { useState } from "react";
import Api from "../Api";
import { useNavigate } from "react-router-dom";

function CarregarFitxer() {
  const [uploadFile, setUploadFile] = useState();

  const navigate = useNavigate();

  const submitForm = async (event) => {
    event.preventDefault();
    const dataArray = new FormData();
    dataArray.append("uploadFile", uploadFile);
    Api.post("/horari/", dataArray, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        navigate("/horari/" + response.data.tren + "/" + response.data.data);
      })
      .catch((error) => {
        // error response
      });
  };

  const handleFileSelect = (event) => {
    setUploadFile(event.target.files[0]);
  };

  return (
    <div>
      <div className="m-3">
        <form onSubmit={submitForm}>
          <label className="mx-3">
            Choisissez le fichier txt de E-HOUAT :{" "}
          </label>
          <input type="file" onChange={handleFileSelect} accept=".txt" />{" "}
          <span> </span>
          <input type="submit" />
        </form>
      </div>
    </div>
  );
}

export default CarregarFitxer;

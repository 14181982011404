import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import FullHorari from "./components/FullHorari";
import CarregarFitxer from "./components/CarregarFitxer";
import LlistaHoraris from "./components/LlistaHoraris";
import AVITrack from "./components/AVITrack/AVITrack";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <div>Error</div>,
    children: [
      {
        path: "/carregar/",
        element: <CarregarFitxer />,
      },
      {
        path: "/horari/:tren/:data",
        element: <FullHorari />,
      },
      {
        path: "/horari/",
        element: <LlistaHoraris />,
      },
      {
        path: "/",
        element: <LlistaHoraris />,
      },
    ],
  },
  {
    path: "/horari/:tren/:data/track",
    element: <AVITrack />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

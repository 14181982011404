import axios from "axios";
var baseURL = { baseURL: process.env.PUBLIC_URL + "/api/" };

// if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
//     baseURL = {baseURL: 'https://mobilitat.bergueda.cat/api/'};
// }

if (window.location.hostname === "localhost") {
  baseURL = { baseURL: "http://localhost/horaire-renfe/public/api/" };
}

export default axios.create(baseURL);

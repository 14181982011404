import React, { useState } from "react";
import Api from "../Api";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/es";

function LlistaHoraris() {
  moment.locale("es");

  const [pantalla, setPantalla] = useState(1);
  const [llistaHoraris, setLlista] = useState(null);
  let dataActual = null;
  let canviaData = true;
  let classNameData = "";

  React.useEffect(() => {
    document.title = "Générateur Fiches Horaires";
    Api.get("/horari/").then((response) => {
      setLlista(response.data);
      setPantalla(2);
    });
  }, []);

  const navigate = useNavigate();

  const anarACarregar = () => {
    navigate("/carregar/");
  };

  const anarATren = (data, tren) => {
    navigate("/horari/" + tren + "/" + data);
  };

  const assignaDataActual = (data) => {
    dataActual = data;
  };

  const assignaClassNameData = (valor) => {
    classNameData = valor;
  };

  const assignaDataActualVar = (valor) => {
    canviaData = valor;
  };

  const miraSiCanviaData = (data) => {
    if (dataActual !== data) {
      assignaDataActual(data);
    }
  };

  const miraSiCanviaDataVar = (data) => {
    console.log("canviaData: " + canviaData);
    if (dataActual !== data) {
      assignaDataActualVar(true);
      assignaClassNameData("canviDataRow");
    } else {
      assignaDataActualVar(false);
      assignaClassNameData("");
    }
  };

  return (
    <div>
      {pantalla === 1 && <div>Chargement...</div>}
      {pantalla === 2 && (
        <div>
          <h3>Horaires disponibles</h3>
          <Button variant="primary" onClick={anarACarregar}>
            Charger une nouvelle fiche horaire
          </Button>
          <div className="llistaHoraris">
            {llistaHoraris.map((horari) => (
              <div>
                {miraSiCanviaDataVar(horari.data)}
                <Row key={horari.data} className={classNameData}>
                  {dataActual !== horari.data && (
                    <Col sm={2} className="canviDataCol">
                      {moment("20" + horari.data).format("L")}
                    </Col>
                  )}
                  {dataActual === horari.data && <Col sm={2}></Col>}
                  <Col sm={10}>
                    <Button
                      variant="terciary"
                      onClick={() => anarATren(horari.data, horari.tren)}
                    >
                      {horari.tren}
                    </Button>
                  </Col>
                  {miraSiCanviaData(horari.data)}
                </Row>
              </div>
            ))}
            {/* {llistaHoraris.map((horari) => (
              <li key={horari.data + "-" + horari.tren}>
                <Link to={"/horari/" + horari.tren + "/" + horari.data}>
                  {moment("20" + horari.data).format("L")} - {horari.tren}
                </Link>
              </li>
            ))} */}
          </div>
          {/* <Link to={"/carregar/"}>Cargar horario nuevo</Link> */}
        </div>
      )}
    </div>
  );
}

export default LlistaHoraris;
